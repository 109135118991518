<template>
  <div class="content">
    <div class="columns">
      <div class="column">
        <h1 class="title is-1">{{ ingredient.name }}</h1>
      </div>
      <div class="column is-narrow">
        <b-button outlined type="is-primary" class="right" icon-left="check" :disabled="isSaveDisabled" @click="saveAndClose">Save & Close</b-button>
      </div>
      <div class="column is-narrow">
        <b-button type="is-primary" class="right" icon-left="content-save" :disabled="isSaveDisabled" @click="save">Save</b-button>
      </div>
    </div>
    <b-field horizontal label="Name">
      <b-input
        ref="nameInput"
        v-model="ingredient.name"
        @blur="save"
      />
    </b-field>
    <b-field horizontal label="Ingredient Aisle">
      <b-select v-model="ingredient.aisle">
        <option v-for="(aisle) in aisles" :value="aisle" :key="aisle.id">{{aisle.name}}</option>
       </b-select>
    </b-field>
    <section class="mt-6">
      <h2 class="subtitle is-5">Common Tips</h2>
      <b-field v-bind:key="tip" v-for="(tip, index) in ingredient.tips">
        <b-input expanded v-bind:value="tip"></b-input>
        <p class="control">
          <b-button icon-right="delete" @click="removeTip(index)" />
        </p>
      </b-field>
      <b-field>
        <!-- TODO: add v-on:keyup.enter="addNewTip" but it's not working -->
        <b-input expanded v-model="newTip" placeholder="e.g. any choi will do" ></b-input>
        <p class="control">
          <b-button type="is-primary" icon-right="plus" :disabled="isAddNewTipDisabled" @click="addNewTip" />
        </p>
        </b-field>
    </section>
  </div>
</template>

<script>
import db from "@/firebase/db"

export default {
  name: 'IngredientView',
  props: [
    'id'
  ],
  data() {
    return {
      aisles: [],
      ingredient: {},
      newTip: null
    };
  },
  firestore: {
    aisles: db.collection('ingredient_aisles').orderBy("position", "asc"),
  },
  computed: {
    isSaveDisabled () {
      return this.ingredient.name == null || this.ingredient.name.trim() == "" || this.ingredient.aisle == null
    },
    isAddNewTipDisabled () {
      return this.newTip == null
    }
  },
  mounted: function () {
    this.$refs.nameInput.focus();
  },
  created: function () {
    // fetch our ingredient
    db.collection('ingredients')
      .doc(this.id)
      .get()
      .then(snapshot => {
        this.ingredient = snapshot.data()
      })
  },
  methods: {
    async saveAndClose(){
      this.save()
        .then(() => {
          this.$router.go(-1)
        })
    },
    async save(){
      if (this.isSaveDisabled) {
        this.$buefy.toast.open({type: 'is-danger', message: `Missing info - Name and Ingredient Aisle must be set!`})
        return;
      }
      db.collection('ingredients')
        .doc(this.id)
        .set(this.ingredient)
        .then(() => {
          this.$buefy.toast.open({type: 'is-success', message: `Ingredient successfully updated!`})
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving ingredient: ${error}`})
        });
    },
    addNewTip() {
      this.newTip = this.newTip.trim()
      if (!this.newTip || this.newTip == "") { return }
      this.newTip.id =
      this.ingredient.tips.push(this.newTip);
      this.saveIngredient();
      this.newTip = null;
    },
    removeTip(tipIndex) {
      this.$delete(this.ingredient.tips, tipIndex);
      this.saveIngredient();
    }
  }
}


</script>

<style scoped>
.title {
  flex-grow: 1;
}
</style>
